import './intro.styles.css'
function Intro() {
    return ( 
        <div className="intro-container">
            <div className="intro-textbox">
                <div className="intro-text-head">I'm The WEB STRATEGIST!</div>
                <div className="intro-text-sub">Tell me what ails you. <br></br>Anything and Everything is acheivable through Technology</div>
                {/* <input type="button" value="About Me" className="intro-button-1" />
                <input type="button" value="Projects" className="intro-button-2" /> */}
            </div>
        </div>
        
     );
}

export default Intro;