import AboutMe from "../../components/about-me/about-me.component";
import './home-page.styles.css'

function HomePage() {
    return ( 
        <div className="home-page">
            <AboutMe/>
        </div>
     );
}

export default HomePage;